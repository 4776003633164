import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'

import { media } from '../../styles/utils'
import { container, padding, type, button, bgImage } from '../../styles/global'
import { renderLink } from '../../utils'

const Features = props => {
    const { bgColour, heading, features, link } = props
    // console.log(link)

    const renderItems = () => {
        return features.map((item, i) => {
            return (
                <Item key={i}>
                    {item.icon && <Icon src={item?.icon?.sizes?.medium2} />}
                    {item.text && (
                        <Description
                            dangerouslySetInnerHTML={{
                                __html: item.text,
                            }}
                        />
                    )}
                </Item>
            )
        })
    }

    return (
        <Wrapper bgColour={bgColour}>
            <Container>
                {heading && (
                    <Heading
                        dangerouslySetInnerHTML={{
                            __html: heading,
                        }}
                    />
                )}
                <Items count={features.length < 3 ? features.length : 3}>
                    {renderItems()}
                </Items>
                {link && (
                    <Button>
                        {renderLink(link, 0)}
                    </Button>
                )}
            </Container>
        </Wrapper>
    )
}

// Shared

const Heading = styled.div``
const Description = styled.div``
const Icon = styled.img``
const Button = styled.div``

// Layout

const Wrapper = styled.div`
    ${tw`w-full text-center text-black`};
    padding-top: 9rem;
    padding-bottom: 9rem;
    background-color: ${props => props.bgColour};

    ${Heading} {
        ${tw`font-medium`};
        font-size: 2rem;
        margin-bottom: 6rem;
    }

    ${Button} {
        margin-top: 4.375rem;
        > * {
            ${button}
            ${tw`bg-green border-none`};
            font-size: 1rem;

            &:hover {
                ${tw`bg-white text-green`};
            }
        }
    }
`

const Container = styled.div`
    ${container}
    ${padding}
    display: flex;
    flex-direction: column;
    align-items: center;
`

// Items

const Items = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.count}, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 4rem;
    width: 100%;

    ${media.tab`
        grid-template-columns: repeat(3, 1fr);
    `}

    ${media.mob`
        grid-template-columns: repeat(2, 1fr);
    `}
`

const Item = styled.div`
    ${tw`flex flex-col items-center`};

    ${Icon} {
        max-height: 6rem;
        max-width: 10rem;
    }

    ${Description} {
        margin-top: 3rem;
        font-size: 1rem;
    }
`

export default Features
