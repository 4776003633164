import React, { useState } from 'react'
import { Link } from 'gatsby'
import ProgressiveImage from 'react-progressive-image'
import styled, { css, createGlobalStyle } from 'styled-components'
import { motion } from 'framer-motion'
import tw from 'tailwind.macro'
import { getGlobalData } from '../../utils'

import { StockistsForm } from '../../components'
import { type, padding, container, hoverState } from '../../styles/global'
import { media } from '../../styles/utils'
import { renderLink } from '../../utils'

import logo from '../../assets/images/logos/sunstar-flooring-logo.svg'

const Footer = props => {
    const { global: data } = getGlobalData()
    // console.log(data)

    const renderMenuItems = menu => {
        if (!menu) return
        return menu.map((item, i) => {
            const link = item?.link
            if (!link) return
            return renderLink(link, i)
        })
    }

    return (
        <Wrapper>
            <Container>
                {/* Top Row */}
                <Grids>
                    <Grid>
                        <Col>
                            <HomeLink
                                onClick={() => {
                                    navigate('/')
                                    if (mobileMenu.active) {
                                        mobileMenu.toggleActive()
                                    }
                                }}
                            >
                                <Logo src={logo} />
                            </HomeLink>
                        </Col>
                        <Col>
                            <CTALink
                                dangerouslySetInnerHTML={{
                                    __html: data?.footer_cta,
                                }}
                            />
                        </Col>
                    </Grid>

                    {/* Bottom Row */}
                    <Grid>
                        <Col>
                            {data.wholesale_message && (
                                <Info>
                                    {data.wholesale_message}
                                </Info>
                            )}
                            <Menu>{renderMenuItems(data?.footer_nav)}</Menu>
                        </Col>
                        <Col>
                            <ContactInfo
                                dangerouslySetInnerHTML={{
                                    __html: data?.footer_contact_info,
                                }}
                            />
                        </Col>

                        <Col>
                            <FormWrapper>
                                <StockistsForm heading={`Find a stockist via our stockists map`} />
                            </FormWrapper>
                        </Col>
                    </Grid>
                </Grids>
            </Container>
        </Wrapper>
    )
}

// Layout

const Wrapper = styled.div`
    ${tw`relative bg-black`};
    z-index: 1;
    padding: 9rem 0;

    ${media.mob`
        padding: 7rem 0;
    `}
`

const Container = styled.div`
    ${container};
    ${padding};
`

const Grids = styled.div`
    ${tw`-mx-8`};

    ${media.mob`
        ${tw`mx-0`};
    `}
`

const Col = styled.div``

const Grid = styled.div`
    ${tw`flex w-full`};

    ${media.mob`
        ${tw`flex-col`};
    `}

    &:first-child {
        ${media.tabPor`
            ${tw`flex-col`};

            ${Col} {
                ${tw`w-full`};
            }

            ${Col}:last-child {
                margin-top: 3rem;
            }
        `}
    }

    &:last-child {
        margin-top: 8rem;

        ${media.mob`
            margin-top: 3rem;
        `}
    }

    ${Col} {
        ${tw`flex flex-col w-1/3 px-8`}

        ${media.mob`
            ${tw`w-full px-0`};
            margin-top: 3rem;
        `}
    }
`

// Logo

const Logo = styled.img``

const HomeLink = styled.div`
    height: 3.125rem;
    cursor: pointer;

    ${Logo} {
        ${tw`h-full`};
        margin-right: 0.6rem;
        width: auto;
    }
`

// CTA

const CTALink = styled.div`
    &,
    * {
        ${tw`font-body text-green`};
        font-size: 2rem;
        font-weight: 700;
        line-height: 1;
    }
`

const Info = styled.div`
    ${tw`text-white`};
    ${type.footerLinks};
`

// Menu

const menuLink = css`
    ${tw`inline-block text-white cursor-pointer`};
    ${type.footerLinks};
    ${hoverState};
`

const Menu = styled.div`
    ${tw`flex flex-col items-start`};
    margin-top: 3rem;

    ${media.tab`
        display: none;
    `}

    .link, a {
        ${menuLink};
    }
`

// Contact Info

const ContactInfo = styled.div`
    ${tw`font-body text-white`};
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.23;

    p:not(:last-child) {
        margin-bottom: 1em;
    }
`

// Stockists Form

const FormWrapper = styled.div`
    width: 100%;

    .form-heading {
        ${tw`font-body text-white`};
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.23;
    }

    .form-thanks {
        margin-top: 2rem;
        ${tw`font-body text-white`};
        font-weight: 300;
        font-size: 1rem;
        line-height: 1.23;
    }

    .dynamic-fields {
        margin-top: 1rem;
    }

    .dynamic-field {
        &, * {
            ${tw`font-body text-white`};
            font-weight: 300;
            font-size: 1rem;
            line-height: 1;
        }

        &:not(:last-child) {
            margin-bottom: 0.75rem;
        }

        input {
            padding: 0.5rem 0;
        }
    }

    button {
        margin-top: 2.5rem;
        padding: 0.9rem 1.5rem 1rem !important;
        ${tw`font-body text-white`};
        font-weight: 300;
        font-size: 1rem;
        line-height: 1;

        &:hover {
            ${tw`bg-white text-black`};
        }
    }
`

export default Footer
