import React, { useState, useContext, Component, useRef } from 'react'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import styled, { css, createGlobalStyle } from 'styled-components'
import Form from 'react-dynamic-form'
import { required, email, phone } from '../../utils/validators'
import tw from 'tailwind.macro'
import { postData } from '../../services/api'

import { Checkbox } from '../'
import { media } from '../../styles/utils'
import { container, padding, button } from '../../styles/global'

const CommercialForm = props => {
    const { heading } = props
    // console.log(props)
    const signupFormRef = useRef(null)
    const [submitting, setSubmitting] = useState(false)
    const [formComplete, setFormComplete] = useState(false)
    const [error, setError] = useState(false)

    const handleSubmit = (fields, resetForm) => {
        const data = {
            companyName: fields.companyName.value,
            companyAddress: fields.companyAddress.value,
            email: fields.email.value,
            name: fields.name.value,
            phone: fields.phone.value,
            notes: fields.notes.value,
            checkboxRetailer: fields.checkboxRetailer.value ? `checked` : false,
            checkboxDesignerSamples: fields.checkboxDesignerSamples.value ? `checked` : false,
            checkboxDesignerProduct: fields.checkboxDesignerProduct.value ? `checked` : false,
            checkboxInstaller: fields.checkboxInstaller.value ? `checked` : false,
        }

        console.log(data)

        setSubmitting(true)

        postData('/sunstar/commercial', data)
            .then(res => {
                console.log(res)
                if (res.success) {
                    setFormComplete(true)
                    setSubmitting(false)
                } else {
                    setError(res.errors[0])
                    setSubmitting(false)
                }

                resetForm()
            })
            .catch(error => {
                setError(error)
                setSubmitting(false)
                resetForm()
            })
    }

    return (
        <Wrapper>
            {formComplete && (
                <Description className={'form-thanks'}
                    dangerouslySetInnerHTML={{
                        __html: `Thanks for your enquiry.<br /> We will get back to you as soon as possible.`
                    }}
                >
                </Description>
            )}

            <FormWrapper
                className={'form-wrapper'}
                formComplete={formComplete}
                submitting={submitting}
            >
                <Form
                    ref={signupFormRef}
                    data={signupFields}
                    moveToInvalidField={false}
                    renderSubmit={false}
                    onSubmit={(fields, resetForm) =>
                        handleSubmit(fields, resetForm)
                    }
                />

                {error && (
                    <Error
                        className={'form-error'}
                        dangerouslySetInnerHTML={{
                            __html: error,
                        }}
                    />
                )}

                <button
                    disabled={submitting ? true : false}
                    onClick={() => {
                        signupFormRef.current &&
                            signupFormRef.current.handleSubmit()
                    }}
                >
                    Submit
                </button>
            </FormWrapper>
            {!formComplete && (
                <Heading className={`form-heading`}>
                    *Sunstar products are only sold through retail stores, for your nearest stockist please use the 'Find a stockist' form.
                </Heading>
            )}
        </Wrapper>
    )
}

const Heading = styled.div``
const Description = styled.div``
const Error = styled.div``

const Wrapper = styled.div`
    ${tw`relative flex flex-col items-start`};

    ${Heading} {
        ${tw`mt-5`};
    }

    ${Heading}, ${Description} {
        ${tw`font-body text-black`};
    }

    .form-thanks {
        ${tw`absolute top-0 inset-x-0 w-full`};
    }

    ${Error} {
        ${tw`font-body`};
        font-size: 1rem;
        margin-top: 1rem;
        color: rgb(203, 0, 0);
    }
`

const FormWrapper = styled.div`
    ${tw`flex flex-col items-start`};

    ${props => {
        if (props.formComplete)
            return css`
                opacity: 0;
                &, * {
                    pointer-events: none;
                }
            `
    }}

    ${props => {
        if (props.submitting)
            return css`
                opacity: 0.5;
                &, * {
                    pointer-events: none;
                }
            `
    }}

    &,
    > div {
        width: 100%;
    }

    .dynamic-field {
        /* error message */
        > *:nth-child(2) {
            margin-top: 0.5rem;
            font-size: 1rem;
        }
    }

    input, textarea {
        ${tw`border-b border-black`};
        width: 100%;
        border-radius: 0;
        appearance: none;
        box-shadow: none;
        background: transparent;

        &,
        &::placeholder {
            ${tw`text-black`};
            opacity: 1;
        }

        &:focus {
            outline: none;
        }

        ${(props) => {
            if (props.disabled)
                return css`
                    pointer-events: none;
                `
        }}
    }

    textarea {
        line-height: 1.25!important;
        resize: none;
    }

    button {
        appearance: none;
        box-shadow: none;
        background-color: transparent;
        ${button}

        &:focus {
            outline: none;
        }
    }
`

class Input extends Component {
    onChange = (e) => {
        const { onChangeValue, validator } = this.props
        const value = e.target.value

        if (onChangeValue) {
            onChangeValue(value, validator)
        }
    }

    renderInput = () => {
        const { type } = this.props
        if (type === 'textarea') {
            return (
                <textarea rows={'4'} onChange={this.onChange} placeholder={this?.props?.placeholder} />    
            )
        }
        return (
            <input onChange={this.onChange} placeholder={this?.props?.placeholder} />
        )
    }

    render() {
        return this.renderInput()
    }
}

export const signupFields = {
    fields: [
        {
            type: 'text',
            name: 'companyName',
            validator: required,
            errorMessage: 'This field is required',
            placeholder: 'Company Name*',
            component: ({ value, placeholder, onChangeValue, validator }) => {
                return (
                    <Input
                        value={value}
                        placeholder={placeholder}
                        onChangeValue={onChangeValue}
                        validator={validator}
                    />
                )
            },
        },
        {
            type: 'textarea',
            name: 'companyAddress',
            validator: required,
            errorMessage: 'This field is required',
            placeholder: 'Company Address*',
            component: ({ value, placeholder, onChangeValue, type, validator }) => {
                return (
                    <Input
                        value={value}
                        placeholder={placeholder}
                        onChangeValue={onChangeValue}
                        type={type}
                        validator={validator}
                    />
                )
            },
        },
        {
            type: 'email',
            name: 'email',
            validator: email,
            errorMessage: 'Please enter a valid email address',
            placeholder: 'Contact Email*',
            component: ({ value, placeholder, onChangeValue, validator }) => {
                return (
                    <Input
                        value={value}
                        placeholder={placeholder}
                        onChangeValue={onChangeValue}
                        validator={email}
                    />
                )
            },
        },
        {
            type: 'name',
            name: 'name',
            placeholder: 'Contact Name',
            component: ({ value, placeholder, onChangeValue, validator }) => {
                return (
                    <Input
                        value={value}
                        placeholder={placeholder}
                        onChangeValue={onChangeValue}
                    />
                )
            },
        },
        {
            type: 'text',
            name: 'phone',
            errorMessage: 'Please enter a valid phone number',
            placeholder: 'Contact Phone Number',
            component: ({ value, placeholder, onChangeValue, validator }) => {
                return (
                    <Input
                        value={value}
                        placeholder={placeholder}
                        onChangeValue={onChangeValue}
                    />
                )
            },
        },
        {
            type: 'textarea',
            name: 'notes',
            placeholder: 'Additional Notes',
            component: ({ value, placeholder, onChangeValue, type }) => {
                return (
                    <Input
                        value={value}
                        placeholder={placeholder}
                        onChangeValue={onChangeValue}
                        type={type}
                    />
                )
            },
        },
        {
            name: 'checkboxRetailer',
            label: 'Retailer - sales rep meeting request',
            component: (props) => {
                return (
                    <Checkbox
                        {...props}
                    />
                )
            }
        },
        {
            name: 'checkboxDesignerSamples',
            label: 'Designer - samples',
            component: (props) => {
                return (
                    <Checkbox
                        {...props}
                    />
                )
            }
        },
        {
            name: 'checkboxDesignerProduct',
            label: 'Designer - product information',
            component: (props) => {
                return (
                    <Checkbox
                        {...props}
                    />
                )
            }
        },
        {
            name: 'checkboxInstaller',
            label: 'Installer - product information',
            component: (props) => {
                return (
                    <Checkbox
                        {...props}
                    />
                )
            }
        },
    ],
}

export default CommercialForm
