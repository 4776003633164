import React, { useState, useRef, useContext } from 'react'
import { navigate } from 'gatsby'
import { useMount, useUnmount } from 'react-use'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import styled from 'styled-components'
import tw from 'tailwind.macro'

import { container, padding, type } from '../../styles/global'
import { headerHeight } from '../Header/Header'
import Submenu from '../Submenu'

import { MobileMenuContext } from '../MobileMenu/context'

const MobileMenu = props => {
    const { menu } = props
    const mobileMenuEl = useRef(null)
    const mobileMenu = useContext(MobileMenuContext)

    console.log(menu)

    useMount(() => disableBodyScroll(mobileMenuEl.current))

    useUnmount(() => clearAllBodyScrollLocks())

    const renderMenu = () => {
        if (!menu) return
        return menu.map((item, i) => {
            const type = item?.link_type
            if (type === 'link') {
                const link = item?.link
                if (!link) return
                return (
                    <MenuItem
                        key={i}
                        onClick={() => {
                            navigate(link?.url)
                            mobileMenu.toggleActive()
                        }}
                        activeClassName={'active'}
                    >
                        {link?.title}
                    </MenuItem>
                )
            }
            if (type === 'submenu') {
                const label = item?.label
                return (
                    <Submenu
                        label={label}
                        menu={[...item?.submenu_links]}
                    />
                )
            }
        })
    }

    return (
        <Wrapper ref={mobileMenuEl}>
            <Container>
                <Menu>
                    {renderMenu()}
                </Menu>
            </Container>
        </Wrapper>
    )
}

// Shared

const Wrapper = styled.div`
    ${tw`w-full h-full`};
    overflow-y: auto;
`

const Container = styled.div`
    ${container}
    ${padding}
`

const MenuItem = styled.div``
const Menu = styled.div`
    ${tw`flex flex-col items-center pt-32 pb-8`};

    ${MenuItem} {
        ${tw`inline-block my-6 text-black`};
        ${type.headerLinks};
        font-size: 2rem;
        /* &.active {
        } */
        &:last-child {
            font-weight: 700;
        }
    }

    .submenu-label {
        ${tw`inline-block my-6 text-black`};
        ${type.headerLinks};
        font-size: 2rem;
    }

    .submenu-wrapper a {
        ${tw`inline-block text-black`};
        ${type.headerLinks};
        line-height: 1.5;
        font-size: 1.25rem;
    }
`

export default MobileMenu
