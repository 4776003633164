import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'

import { media } from '../../../styles/utils'
import { container, padding, hoverState, type } from '../../../styles/global'

const BlockFeatures = props => {
    const { features } = props
    if (!features) return

    const renderItems = () => {
        return features.map((item, i) => {
            return (
                <Item key={i}>
                    <Heading>
                        {item.title}
                    </Heading>
                    <Description
                        className={'tiny-mce'}
                        dangerouslySetInnerHTML={{
                            __html: item.content,
                        }}
                    />
                </Item>
            )
        })
    }

    return (
        <Items>
            {renderItems()}
        </Items>
    )
}

// Shared

const Heading = styled.div``
const Description = styled.div``

const Items = styled.div`
    ${tw`flex flex-row flex-wrap w-full`};
    margin-bottom: -5.375rem;
`

const Item = styled.div`
    ${tw`w-1/2 px-3`};
    margin-bottom: 5.375rem;

    ${media.mob`
        ${tw`w-full px-0`};
    `}

    &:nth-child(odd) {
        padding-right: 1.03125rem;

        ${media.mob`
            ${tw`pr-0`};
        `}
    }

    &:nth-child(even) {
        padding-left: 1.03125rem;

        ${media.mob`
            ${tw`pl-0`};
        `}
    }

    ${Heading} {
        ${tw`border-b border-green`};
        ${type.featureHeading};
        padding-bottom: 10px;
    }

    ${Description} {
        padding-top: 42px;
        /* check global .tiny-mce styles */
    }
`

BlockFeatures.wrapper = css``

export default BlockFeatures
