import React from 'react'
import styled, { css } from 'styled-components'
import { media } from '../../styles/utils';
import tw from 'tailwind.macro'

class CheckboxField extends React.Component {	

    state = {
        active: false,
    }

    toggle = (state) => {
        const { active } = this.state;
        const { onChangeValue, validator } = this.props;

        this.setState({
            active: !active
        })

        onChangeValue(!active, validator)
    }

	render() {
        const { active } = this.state;
        const { value, onChangeValue, validator, label } = this.props;

        return (
            <Wrapper
                onClick={this.toggle}
            >
                <Checkbox
                    className={'checkbox-check'}
                    active={active}
                />
                <Label
                    className={'checkbox-label'}
                    dangerouslySetInnerHTML={{__html: label}}
                />
            </Wrapper>
        )
	}
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    border: 0;

    display: flex;
    cursor: pointer;
    user-select: none;

    margin: 8px 0;
`

const Label = styled.div`
    &, a, p {
        /* font-size: 1rem; */
        line-height: 1;
        margin-bottom: 0;
    }

    a {
        text-decoration: underline;
    }
`

const Checkbox = styled.div`
    border: 1px solid black;
    width: 16px;
    height: 16px;
    position: relative;
    margin-right: 10px;
    flex: 0 1 16px;
    border-radius: 0;
    min-width: 16px;

    ${props => {
        if (props.active) return css`
            &::before {
                content: '';
                width: 9px;
                height: 9px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: black;
            }
        `
    }}
`

export default CheckboxField

