import React from 'react'
import styled, { css } from 'styled-components'
import { container, padding } from '../../styles/global'
import { media } from '../../styles/utils'

const Grid = props => {
    const { columns, tabColumns, items } = props

    const renderItems = () => {
        return items.map((item, i) => {
            return (
                <Item
                    className={'grid-item'}
                    key={i}
                >
                    {item}
                </Item>
            )
        })
    }

    return (
        <Items
            className={'grid-items'}
            columns={columns}
        >
            {items && renderItems()}
        </Items>
    )
}

const Item = styled.div``

const Items = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.columns}, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    width: 100%;

    ${media.mob`
        grid-template-columns: repeat(1, 1fr);
    `}
`

export default Grid
