import React from 'react'
import styled, { css } from 'styled-components'
import { container, padding } from '../../styles/global'
import { media } from '../../styles/utils'
import tw from 'tailwind.macro'

const PageHeader = props => {
    const { title, text } = props

    return (
        <Wrapper
            className={'page-header'}
        >
            {title && (
                <Heading>{title}</Heading>
            )}
            {text && (
                <Description
                    dangerouslySetInnerHTML={{
                        __html: text,
                    }}
                />
            )}
        </Wrapper>
    )
}

const Heading = styled.div``
const Description = styled.div``

const Wrapper = styled.div`
    ${tw`w-full`};
    padding-top: 9rem;
    padding-bottom: 7rem;

    ${Heading}, ${Description} {
        ${tw`font-medium`};
        font-size: 2rem;
        line-height: 1.2;
    }

    ${Heading} {
        ${tw`text-black`};
    }

    ${Description} {
        ${tw`text-grey`};
        margin-top: 0.25rem;
    }
`

export default PageHeader
