import { css } from 'styled-components'
import tw from 'tailwind.macro'
import { media } from './utils'

/*-----------------------------------*/
/* Global Type
/*-----------------------------------*/

const type = {
    headerLinks: css`
        ${tw`font-body`};
        font-size: 1rem;
        font-weight: 300;
        line-height: 1;
    `,
    body: css`
        ${tw`font-body`};
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.23;
    `,
    featureHeading: css`
        ${tw`font-body`};
        font-size: 1.5rem;
        font-weight: 300;
        line-height: 1.18;
    `,
    footerLinks: css`
        ${tw`font-body`};
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5;
    `,
}

export default type
