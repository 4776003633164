import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'

import { media } from '../../../styles/utils'
import { container, padding, hoverState, type } from '../../../styles/global'

const BlockText = props => {
    const { text } = props

    return (
        <Wrapper>
            <Text
                className={'tiny-mce'}
                dangerouslySetInnerHTML={{
                    __html: `${text}`,
                }}
            />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    ${tw`w-full`};
`

export const Text = styled.div`
    /* check global .tiny-mce styles */
`

BlockText.wrapper = css``

export default BlockText
