import React, { useState, useContext, Component, useRef } from 'react'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import styled, { css, createGlobalStyle } from 'styled-components'
import { required, email } from '../../utils/validators'
import tw from 'tailwind.macro'
import { postData } from '../../services/api'

import { media } from '../../styles/utils'
import { container, padding, button } from '../../styles/global'

const StockistsForm = props => {
    const { heading } = props
    // console.log(props)

    return (
        <Wrapper>
            <Heading className={`form-heading`}>
                {heading && heading}
            </Heading>

            <button
                className="link"
                onClick={e => {
                    e.preventDefault()
                    navigate(`/stockists/`)
                }}
            >
                Find a stockist
            </button>
        </Wrapper>
    )
}

const Heading = styled.div``
const Description = styled.div``
const Error = styled.div``

const Wrapper = styled.div`
    ${tw`flex flex-col items-start`};

    ${Heading} {
        ${tw`font-body text-white`};
    }

    button {
        appearance: none;
        box-shadow: none;
        background-color: transparent;
        ${button}

        &:focus {
            outline: none;
        }
    }
`

export default StockistsForm
