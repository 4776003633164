import React, { useContext } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import Transition from '../../transition'
import tw from 'tailwind.macro'
import { useMount } from 'react-use'

import { MetaTags, Footer, Block } from '../'
import { media } from '../../styles/utils'
import { container } from '../../styles/global'
import { headerHeight } from '../Header/Header'

const Layout = ({ children, meta }) => {
    return (
        <>
            <MetaTags {...meta} />
            <Wrapper>
                <Transition>
				    {children}
                    <Footer/>
                </Transition>
            </Wrapper>
        </>
    )
}


const Wrapper = styled.div`
    ${tw`w-full h-full`};
    padding-top: ${headerHeight};
`

export default Layout
