import React from "react"
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { useLocation } from 'react-use';

const Transition = ({children}) =>  {
	const location = useLocation();
		
	const duration = 0.35;
	const variants = {
		initial: {
			opacity: 0,
		},
		animate: {
			opacity: 1,
			transition: {
				duration: duration,
				delay: duration,
				when: 'beforeChildren',
			},
		},
	}
	

	return (
		<AnimatePresence>
			<Wrapper
				key={location.pathname}
				{...variants}
			>
				{children}	
			</Wrapper>
		</AnimatePresence>
	)
}

const Wrapper = styled(motion.div)`
	width: 100%;
	height: 100%;
`

export default Transition