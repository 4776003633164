import { css, createGlobalStyle } from 'styled-components'
import { media } from './utils'
import tw from 'tailwind.macro'

import globalType from './type'
// Re-export type
export const type = globalType

export const maxWidth = 1440

export const hoverState = css`
    opacity: 1;
    transition: 0.15s opacity ease;

    &:hover {
        opacity: 0.7;
    }
`

export const GlobalStyles = createGlobalStyle`
    /* base for rem units */
    html {
        font-size: 12px;
    }

    @media (min-width: 375px) {
        html {
            font-size: calc(12px + 4 * (100vw - 375px) / 1065);
        }
    }
    
    @media (min-width: ${maxWidth}px) {
        html {
            font-size: 16px;
        }
    }

    html,
    body {
        ${tw`m-0 p-0 w-full h-full font-body leading-tight bg-white text-black`};
        font-weight: normal;
        -webkit-font-smoothing: antialiased;
    }
    
    a {
        text-decoration: none;
    }

    h1, h2, h3, h4, h5, h6 {
        font-size: 1rem;
        font-weight: normal;
        margin: 0;
    }

    b, strong {
        font-weight: 700;
    }

    ul {
        list-style: disc;
        padding-left: 2rem;
    }
 
    * {
        -webkit-overflow-scrolling: touch;
    }

    .tiny-mce {
        p, ul, h3, h4, hr {
            &:not(:first-child) {
                margin-top: 2rem;
            }   
        }

        ${globalType.body};

        h3 {
            ${globalType.heading1};
        }

        h4 {
            ${globalType.heading2};
        }

        a {
            /* text-decoration: underline; */
            ${hoverState};
        }
    }
`

export const padding = css`
    padding-left: 4rem;
    padding-right: 4rem;
    box-sizing: border-box;

    ${media.tab`
        padding-left: 2rem;
        padding-right: 2rem;
    `}
`

export const container = css`
    ${tw`w-full h-full mx-auto`};
    width: ${maxWidth}px;
    max-width: 100%;
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`

export const button = css`
    max-width: 100%;
	padding: 1rem 2rem;
	background-color: transparent;
	border-radius: 2rem;
    border: 1px solid white;
    line-height: 1;
    color: white;
    cursor: pointer;
    transition: all 0.15s ease;

    @media (min-width: 1280px) {
        min-width: 14rem;
    }

	display: flex;
	justify-content: center;
	align-items: center;
`