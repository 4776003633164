import React, { useState, useContext } from 'react'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import { useMedia } from 'react-use'
import MobileMenu from '../MobileMenu'
import { MobileMenuContext } from '../MobileMenu/context'

import { media } from '../../styles/utils'
import { container, padding, hoverState, type } from '../../styles/global'
import { renderLink } from '../../utils'

export const headerHeight = '6.25rem'

const Submenu = props => {
    const { label, menu } = props
    const [active, setActive] = useState(false)
    const isMobileMenu = useMedia('(max-width: 1275px)', false)

    const renderDropdown = () => {
        if (!menu || !menu?.length) return <></>

        const items = menu.map((item, index) => {
            const link = item?.submenu_link
            if (!link) return
            return renderLink(link, index)
        })

        return (
            <div
                className='absolute top-full'
                style={{
                    left: '50%',
                    transform: 'translateX(-50%)'
                }}
            >
                <div
                    className='pt-4 tab:pt-0'
                >
                    <div
                        className='flex w-full flex-col items-start gap-3 bg-white px-8 py-6'
                        style={{
                            boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.25)',
                            minWidth: '22rem',
                        }}
                    >
                        {items}
                    </div>
                </div>
            </div>
        )
    }

    return (
            <div
                className='relative z-10 inline-block mx-2 submenu-wrapper'
                onMouseEnter={() => {
                    if (!isMobileMenu) {
                        setActive(true)
                    }
                }}
                onMouseLeave={() => {
                    if (!isMobileMenu) {
                        setActive(false)
                    }
                }}
                onClick={() => {
                    if (isMobileMenu) {
                        setActive(!active)
                    }
                }}
            >
                <div className='submenu-label'>
                    {label}
                </div>
                {active && renderDropdown()}
            </div>
    )
}

export default Submenu