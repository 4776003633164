import { BlockText, BlockFeatures, BlockImage, BlockStockists } from './templates'

export const resolveBlock = layout => {
    let block

    // Map layout strings to blocks

    const blocks = {
        text_block: BlockText,
        features_block: BlockFeatures,
        image_block: BlockImage,
        stockists_block: BlockStockists,
    }

    return blocks[layout]
}
