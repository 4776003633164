// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-commercial-enquiries-js": () => import("./../../../src/pages/commercial-enquiries.js" /* webpackChunkName: "component---src-pages-commercial-enquiries-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-product-guides-js": () => import("./../../../src/pages/product-guides.js" /* webpackChunkName: "component---src-pages-product-guides-js" */),
  "component---src-pages-product-range-js": () => import("./../../../src/pages/product-range.js" /* webpackChunkName: "component---src-pages-product-range-js" */),
  "component---src-pages-stockists-js": () => import("./../../../src/pages/stockists.js" /* webpackChunkName: "component---src-pages-stockists-js" */),
  "component---src-templates-product-single-js": () => import("./../../../src/templates/product-single.js" /* webpackChunkName: "component---src-templates-product-single-js" */)
}

