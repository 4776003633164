import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'

import { StockistsForm } from '../../../components'
import { media } from '../../../styles/utils'
import { container, padding, hoverState, type } from '../../../styles/global'

const BlockStockists = props => {
    return (
        <Wrapper>
            <Container>
                <StockistsForm heading={'Find a stockist via our stockists map'} />
            </Container>
        </Wrapper>
    )
}

const Container = styled.div``

const Wrapper = styled.div`
    ${tw`w-full bg-green`};
    padding-top: 8.5rem;
    padding-bottom: 9.375rem;

    ${Container} {
        ${container};
        ${padding};
    }

    .form-heading {
        ${tw`mx-auto font-body text-white text-center`};
        font-weight: 500;
        font-size: 2rem;
        line-height: 1;
    }

    .form-thanks, .form-error {
        margin-top: 2rem;
        ${tw`font-body text-white mx-auto text-center`};
        font-weight: 300;
        font-size: 1rem;
        line-height: 1.23;
    }

    .dynamic-fields {
        ${tw`mx-auto`};
        margin-top: 4rem;
        max-width: 60rem;
    }

    .dynamic-field {
        &#suburb, &#postcode {
            width: calc(50% - 1rem);
        }

        &#suburb {
            margin-right: 1rem;
        }

        &#postcode {
            margin-left: 1rem;
        }

        &, * {
            ${tw`font-body text-white`};
            font-weight: 300;
            font-size: 1rem;
            line-height: 1;
        }

        &:not(:last-child) {
            margin-bottom: 0.75rem;
        }

        input {
            padding: 0.5rem 0;
        }
    }

    button {
        margin-top: 4rem;
        margin-left: auto;
        margin-right: auto;
        padding: 1rem 4rem 1.2rem !important;
        ${tw`font-body text-white`};
        font-weight: 300;
        font-size: 1rem;
        line-height: 1;
        min-width: 18rem!important;

        &:hover {
            ${tw`bg-white text-green`};
        }
    }
`

BlockStockists.wrapper = css`
    margin-bottom: 0;
`

export default BlockStockists
